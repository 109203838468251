import React from 'react'
import { Icon, StatusWidget, Text, Token, VStack } from '@revolut/ui-kit'
import RefreshInterviewStages from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/RefreshInterviewStages'

type Props = {
  roundId: number
  onRefresh?: () => void
}

export const NoStagesBanner = ({ roundId, onRefresh }: Props) => {
  return (
    <StatusWidget>
      <StatusWidget.Title>
        <VStack align="center" space="s-8" mt="s-8">
          <Icon name="ExclamationTriangle" color={Token.color.greyTone20} />
          <Text color={Token.color.greyTone50}>
            Unable to view the hiring stages for this candidate
          </Text>
        </VStack>
      </StatusWidget.Title>
      <StatusWidget.Description>
        Please try to refresh the page or contact our team
      </StatusWidget.Description>
      {onRefresh && (
        <StatusWidget.Action>
          <RefreshInterviewStages
            roundId={roundId}
            variant="action"
            onRefresh={onRefresh}
          />
        </StatusWidget.Action>
      )}
    </StatusWidget>
  )
}
