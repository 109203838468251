import React from 'react'
import { Text, Widget } from '@revolut/ui-kit'
import {
  InterviewResultToTitle,
  InterviewFeedbackWithoutStageInterface,
} from '@src/interfaces/interviewTool'

type Props = {
  scorecard: InterviewFeedbackWithoutStageInterface
}

const ChangesDetected = ({ scorecard }: Props) => {
  if (!scorecard.deviation_justification) {
    return null
  }

  return (
    <Widget p="s-16" mb="s-16">
      <Text use="div" variant="primary" pb="s-8" color="orange">
        The interviewer changed the recommendation from the system suggestion
      </Text>
      {scorecard.result &&
        scorecard.recommended_result &&
        scorecard.result !== scorecard.recommended_result && (
          <Text use="div" variant="caption">
            {InterviewResultToTitle[scorecard.recommended_result]}
            {' -> '}
            {InterviewResultToTitle[scorecard.result]}
          </Text>
        )}
      <Text use="div" variant="caption" mt="s-16" fontWeight={500}>
        Justification:
      </Text>
      <Text use="div" variant="caption" whiteSpace="break-spaces">
        {scorecard?.deviation_justification}
      </Text>
    </Widget>
  )
}

export default ChangesDetected
