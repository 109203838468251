import React from 'react'
import Tooltip from '@components/Tooltip/Tooltip'
import {
  InterviewFeedbackStatus,
  InterviewResult,
  InterviewResultToTitle,
} from '@src/interfaces/interviewTool'
import { Icon, IconName, Tag, Text, Token } from '@revolut/ui-kit'

interface Props {
  status: InterviewFeedbackStatus
  result?: InterviewResult | null | ''
  showTooltip?: boolean
  showLabel?: boolean
  higherSeniority?: boolean
  lowerSeniority?: boolean
}

interface IconDataInterface {
  icon: IconName
  text: string
}

const getIconData = (
  status: InterviewFeedbackStatus,
  result?: InterviewResult | null | '',
): IconDataInterface => {
  if (status === InterviewFeedbackStatus.completed) {
    switch (result) {
      case InterviewResult.Hire:
        return {
          icon: 'SocialLike',
          text: InterviewResultToTitle[InterviewResult.Hire],
        }
      case InterviewResult.NoHire:
        return {
          icon: 'SocialDislike',
          text: InterviewResultToTitle[InterviewResult.NoHire],
        }
      case InterviewResult.StrongHire:
        return {
          icon: 'DoubleThumbs',
          text: InterviewResultToTitle[InterviewResult.StrongHire],
        }
      case InterviewResult.StrongNoHire:
        return {
          icon: 'SocialDislike',
          text: InterviewResultToTitle[InterviewResult.StrongNoHire],
        }
    }
  }

  if (status === InterviewFeedbackStatus.rejected) {
    return {
      icon: 'CrossCircle',
      text: 'Rejected',
    }
  }

  return {
    icon: '16/SandWatch',
    text: 'Pending',
  }
}

export const InterviewResultIcon = ({
  status,
  result,
  showTooltip,
  showLabel,
  higherSeniority,
  lowerSeniority,
}: Props) => {
  const data = getIconData(status, result)

  const getColor = () => {
    if (status === InterviewFeedbackStatus.completed) {
      if (result === InterviewResult.NoHire || result === InterviewResult.StrongNoHire) {
        return {
          bg: Token.color.red_10,
          color: Token.color.red,
        }
      }

      if (result === InterviewResult.Hire || result === InterviewResult.StrongHire) {
        return {
          bg: Token.color.teal_10,
          color: Token.color.teal,
        }
      }
    }

    return {
      bg: Token.color.greyTone8,
      color: Token.color.greyTone50,
    }
  }

  const tagColor = getColor()

  return (
    <>
      <Tooltip placement="top" text={data.text} hide={!showTooltip}>
        <Tag
          variant="status"
          useIcon={data.icon}
          bg={tagColor.bg}
          color={tagColor.color}
        />
        {higherSeniority && (
          <Icon name="ArrowUp" color={Token.color.greyTone50} size={13} />
        )}
        {lowerSeniority && (
          <Icon name="ArrowDown" color={Token.color.greyTone50} size={13} />
        )}
        {showLabel && (
          <Text ml="s-4" whiteSpace="nowrap">
            {data.text}
          </Text>
        )}
      </Tooltip>
    </>
  )
}
