import React, { useCallback, useContext, useMemo, useState } from 'react'
import {
  CandidateActiveActionType,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'

export interface CandidateProfileContextInterface {
  activeAction?: CandidateActiveActionType
  setActiveAction: (type?: CandidateActiveActionType) => void
  resetActiveAction: () => void
  activeStage?: InterviewStageWithoutRoundInterface
  setActiveStage: (stage?: InterviewStageWithoutRoundInterface) => void
}

export const CandidateProfileContext =
  React.createContext<CandidateProfileContextInterface>({
    setActiveAction: () => {},
    resetActiveAction: () => {},
    activeAction: undefined,
    activeStage: undefined,
    setActiveStage: () => {},
  })

type Props = {
  children: React.ReactNode
  activeActionDefault?: CandidateActiveActionType
}

export const CandidateProfileContextProvider = ({
  children,
  activeActionDefault,
}: Props) => {
  const [activeAction, setActiveAction] = useState<CandidateActiveActionType | undefined>(
    activeActionDefault,
  )
  const [activeStage, setActiveStage] = useState<InterviewStageWithoutRoundInterface>()

  const resetActiveAction = useCallback(() => {
    setActiveAction(undefined)
  }, [setActiveAction])

  const value: CandidateProfileContextInterface = useMemo(
    () => ({
      activeAction,
      setActiveAction,
      resetActiveAction,
      activeStage,
      setActiveStage,
    }),
    [activeAction, setActiveAction, resetActiveAction, activeStage, setActiveStage],
  )

  return (
    <CandidateProfileContext.Provider value={value}>
      {children}
    </CandidateProfileContext.Provider>
  )
}

export const useCandidateProfileContext = () => {
  return useContext(CandidateProfileContext)
}
