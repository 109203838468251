import React from 'react'
import { Spinner, StatusWidget, Text, Token, VStack } from '@revolut/ui-kit'

export const RefreshingStagesBanner = () => {
  return (
    <StatusWidget>
      <StatusWidget.Title>
        <VStack align="center" space="s-16">
          <Spinner color={Token.color.accent} size={96} />
          <Text color={Token.color.greyTone50}>Generating interview stages...</Text>
        </VStack>
      </StatusWidget.Title>
    </StatusWidget>
  )
}
