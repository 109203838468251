import {
  changeInterviewStage,
  refreshInterviewStages,
  skipScheduledInterview,
  unSkipScheduledInterview,
} from '@src/api/recruitment/interviews'
import {
  InterviewStageWithoutRoundInterface,
  InterviewType,
} from '@src/interfaces/interviewTool'
import { matchPath } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { LOCAL_STORAGE } from '@src/constants/api'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { useEffect, useState } from 'react'

export const changeStage = async (roundId: number, stageId: number) => {
  const response = await changeInterviewStage(roundId, stageId)
  return response.data
}

export const skipInterview = async (roundId: number, stageId: number) => {
  await skipScheduledInterview(roundId, stageId)
}

export const unskipInterview = async (roundId: number, stageId: number) => {
  await unSkipScheduledInterview(roundId, stageId)
}

export const NON_ELIGIBLE_FEEDBACK_STAGES: InterviewType[] = [
  'online_test',
  'offer',
  'cv_screening',
]

export const useShowCandidateSwitcher = () => {
  let savedLocationHistory = []
  const whiteList = [
    ROUTES.RECRUITMENT.CANDIDATES,
    ROUTES.FORMS.ANONYMIZE_CANDIDATE.ANY,
    ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.CANDIDATE,
    ROUTES.FORMS.ADD_INTERVIEW_ROUND.ANY,
    ROUTES.FORMS.CANDIDATE_ADD_DOCUMENT,
    ROUTES.FORMS.CANDIDATE.ANY,
    ROUTES.FORMS.INTERVIEW_FEEDBACK.ANY,
    ROUTES.FORMS.SEND_EMAIL.CANDIDATE_DATA,
    ROUTES.FORMS.OFFER_CREATION.ANY,
  ]

  try {
    const rawHistory = workspaceLocalStorage.getItem(LOCAL_STORAGE.LOCATION_HISTORY)
    savedLocationHistory = rawHistory ? JSON.parse(rawHistory) : []
  } catch (e) {
    console.error(e)
  }

  try {
    if (savedLocationHistory?.length >= 2) {
      const prevPath = savedLocationHistory[1]
      const prevURL = prevPath
        ? new URL(`${window.location.protocol}${window.location.hostname}${prevPath}`)
        : null

      if (prevURL) {
        return whiteList.some(url => !!matchPath(prevURL.pathname, url))
      }
    }

    return false
  } catch (e) {
    console.error(e)
    return false
  }
}

export const useRefreshInterviewStages = (
  stages: InterviewStageWithoutRoundInterface[],
  stagesFetched: boolean,
  roundId?: number,
  onRefresh?: () => void,
) => {
  const [loading, setLoading] = useState(false)

  const onRefreshStages = async () => {
    if (roundId) {
      setLoading(true)
      try {
        await refreshInterviewStages(roundId)
        onRefresh?.()
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (stagesFetched && !stages.length && roundId) {
      onRefreshStages()
    }
  }, [stages, roundId, stagesFetched])

  return { loading }
}
