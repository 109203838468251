import React from 'react'
import { Text, Widget } from '@revolut/ui-kit'

type Props = {
  notes?: string
}

const Notes = ({ notes }: Props) => {
  return (
    <Widget p="s-16" mb="s-32">
      <Text use="div" variant="primary" pb="s-8">
        Notes from interviewer
      </Text>
      <>
        <Text use="div" variant="caption" fontWeight={500}>
          Overall impression:
        </Text>
        <Text use="div" variant="caption" whiteSpace="break-spaces">
          {notes}
        </Text>
      </>
    </Widget>
  )
}

export default Notes
