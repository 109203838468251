import React from 'react'
import { CommentsAPIInterface } from '@src/interfaces/chat'
import Chat from '@components/Chat/Chat'
import { ChatMessageType } from '@components/Chat/common'

export interface CommonCommentsSectionProps {
  onSideOpen?: () => void
  onSideClose?: () => void
  setSidebarOpen?: React.MutableRefObject<(open: boolean) => void>
  title?: string
  maxShown?: number
  canAddComments?: boolean
  onRefetch?: () => void
  messageBorder?: boolean
  withFileUploader?: boolean
}

export interface CommentsSectionProps extends CommonCommentsSectionProps {
  api: CommentsAPIInterface
  disableTodolistFeature?: boolean
  showArchived?: boolean
  variant?: 'chat' | 'compact'
}

const CommentsSection = ({
  api,
  showArchived,
  onRefetch,
  variant = 'chat',
  withFileUploader,
  ...chatProps
}: CommentsSectionProps) => {
  const { data, isLoading, refetch } = api.useGetComments(showArchived)

  return (
    <Chat
      data={data?.results || null}
      isLoading={isLoading}
      refetch={() => {
        onRefetch?.()
        return refetch()
      }}
      type={ChatMessageType.Comment}
      onEdit={api.editComment}
      onArchive={api.archiveComment}
      onAddMessage={api.addComment}
      onResolve={api.resolveComment}
      withFileUploader={withFileUploader}
      {...chatProps}
    />
  )
}

export default CommentsSection
