import React from 'react'
import {
  InterviewFeedbackStatus,
  CandidateInterviewStageTableRowInterface,
  InterviewStatuses,
} from '@src/interfaces/interviewTool'
import { HStack, Icon, IconName, Text, textChain, Token } from '@revolut/ui-kit'
import { notReachable } from '@src/utils/notReachable'
import * as Sentry from '@sentry/react'

type Props = {
  stage: CandidateInterviewStageTableRowInterface
  isNext: boolean
}

type LabelProps = {
  type: 'warning' | 'negative' | 'info' | 'attention' | 'success'
  icon: IconName
  text: string
}

const Label = ({ type, icon, text }: LabelProps) => {
  const getColor = () => {
    switch (type) {
      case 'warning':
        return Token.color.orange

      case 'negative':
        return Token.color.red

      case 'info':
        return Token.color.greyTone50

      case 'attention':
        return Token.color.accent

      case 'success':
        return Token.color.green

      default:
        throw notReachable(type)
    }
  }

  return (
    <Text variant="tiny" color={getColor()}>
      <HStack gap="s-8" align="center" style={{ justifyContent: 'end' }}>
        <Icon name={icon} size={14} />
        {text}
      </HStack>
    </Text>
  )
}

export const StageStatus = ({ stage, isNext }: Props) => {
  try {
    switch (stage.scheduling_status) {
      case InterviewStatuses.pending_scheduling:
        return <Label type="warning" icon="16/SandWatch" text="Pending scheduling" />

      case InterviewStatuses.pending_cv_screening:
        return <Label type="warning" icon="16/SandWatch" text="Pending CV screening" />

      case InterviewStatuses.pending_candidate_response:
        return (
          <Label type="warning" icon="16/SandWatch" text="Pending candidate response" />
        )

      case InterviewStatuses.scheduling_expired:
        return (
          <Label type="warning" icon="16/SandWatch" text="Pending candidate response" />
        )

      case InterviewStatuses.test_not_sent: {
        if (isNext) {
          return null
        }

        return <Label type="warning" icon="16/SandWatch" text="Test not sent" />
      }

      case InterviewStatuses.test_sent:
        return <Label type="info" icon="Envelope" text="Test sent" />

      case InterviewStatuses.test_completed:
        return <Label type="info" icon="CheckSuccess" text="Test completed" />

      case InterviewStatuses.interview_scheduled: {
        return <Label type="info" icon="CalendarDate" text="Scheduled" />
      }

      case InterviewStatuses.awaiting_feedback: {
        const pending = stage.interview_feedbacks.filter(
          item => item.status === InterviewFeedbackStatus.pending,
        ).length

        return (
          <Label
            type="warning"
            icon="16/SandWatch"
            text={textChain(
              'Awaiting feedback',
              `${pending}/${stage.interview_feedbacks.length}`,
            )}
          />
        )
      }

      case InterviewStatuses.interviewer_rejected:
        return <Label type="negative" icon="Cross" text="Interviewer rejected" />

      case InterviewStatuses.interview_cancelled:
        return <Label type="negative" icon="Cross" text="Interview cancelled" />

      case InterviewStatuses.awaiting_interviewer_availability:
        return (
          <Label
            type="warning"
            icon="16/SandWatch"
            text="Awaiting interviewer availability"
          />
        )

      case InterviewStatuses.offer_signed:
        return <Label type="success" icon="CheckSuccess" text="Offer signed" />

      case InterviewStatuses.offer_not_sent: {
        if (isNext) {
          return null
        }

        return <Label type="warning" icon="16/SandWatch" text="Offer not sent" />
      }

      case InterviewStatuses.pending_candidate_signature:
        return (
          <Label type="warning" icon="16/SandWatch" text="Pending candidate signature" />
        )

      case InterviewStatuses.offer_expired:
        return <Label type="info" icon="Time" text="Offer expired" />

      case InterviewStatuses.offer_declined_recruiter:
        return <Label type="negative" icon="Cross" text="Offer declined by recruiter" />

      case InterviewStatuses.offer_declined_candidate:
        return <Label type="negative" icon="Cross" text="Offer declined by candidate" />

      case InterviewStatuses.offer_cancelled_recruiter:
        return <Label type="negative" icon="Cross" text="Offer cancelled by recruiter" />

      case InterviewStatuses.feedback_submitted:
        return <Label type="info" icon="CheckSuccess" text="Feedback submitted" />

      case InterviewStatuses.cv_seen:
        return <Label type="info" icon="EyeShow" text="CV seen" />

      case InterviewStatuses.not_started:
        return null

      default:
        throw notReachable(stage.scheduling_status)
    }
  } catch (e) {
    Sentry.captureException(e)
    return null
  }
}
