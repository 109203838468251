import React from 'react'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { FormattedMessage } from 'react-intl'
import { AddGoalAction } from './AddGoalAction'
import { EntityTypes } from '@src/constants/api'

export const EmptyGoalTable = ({
  preselectedType,
}: {
  preselectedType:
    | EntityTypes.companyV2
    | EntityTypes.department
    | EntityTypes.team
    | undefined
}) => {
  return (
    <EmptyTableRaw
      title={
        <FormattedMessage
          defaultMessage="Goals go here"
          id="performance.goals.emptyTable.title"
        />
      }
      action={
        <AddGoalAction
          preselectedType={preselectedType}
          variant="text"
          label={
            <FormattedMessage
              id="performance.goals.emptyTable.addNewAction"
              defaultMessage="Create your first goal"
            />
          }
        />
      }
    />
  )
}
