import { Dropdown, Icon, Spinner, TextButton, Token } from '@revolut/ui-kit'
import React, { useRef } from 'react'

type Props = {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  loading?: boolean
  children: React.ReactNode
}

const StagesTableActions = ({ isOpen, setOpen, loading = false, children }: Props) => {
  const ref = useRef(null)

  return (
    <>
      <TextButton
        width="100%"
        ref={ref}
        onClick={() => {
          setOpen(!isOpen)
        }}
        data-testid="table-action-btn"
      >
        {loading ? <Spinner /> : <Icon name="Ellipsis" color={Token.color.greyTone50} />}
      </TextButton>
      <Dropdown open={isOpen} anchorRef={ref} onClose={() => setOpen(false)}>
        {children}
      </Dropdown>
    </>
  )
}

export default StagesTableActions
