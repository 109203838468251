import React from 'react'
import { Box, Flex, Widget, Text, HStack, Icon, Token } from '@revolut/ui-kit'
import {
  InterviewResultToTitle,
  InterviewFeedbackWithoutStageInterface,
} from '@src/interfaces/interviewTool'
import { InterviewResultIcon } from '@components/ColumnInserts/InterviewResult/InterviewResultIcon'
import { formatPercentage } from '@src/utils/format'
import Tooltip from '@components/Tooltip/Tooltip'
import { InfoOutline } from '@revolut/icons'
import { SeniorityInterface } from '@src/interfaces/seniority'

type Props = {
  expectedSeniority: SeniorityInterface | null
  scorecard?: InterviewFeedbackWithoutStageInterface
}

const Overview = ({ scorecard, expectedSeniority }: Props) => {
  if (!scorecard) {
    return null
  }

  return (
    <>
      <Widget p="s-16" mb="s-16">
        <Flex>
          <Box width={1 / 2}>
            <Text use="div" color="grey-tone-50" variant="caption">
              Recommendation
            </Text>
            <Text use="div" variant="h5" display="flex">
              <InterviewResultIcon
                result={scorecard.result}
                status={scorecard.status}
                showTooltip
              />
              {scorecard.result ? (
                <Text ml="10px">{InterviewResultToTitle[scorecard.result]}</Text>
              ) : (
                ''
              )}
            </Text>
          </Box>
          <Box width={1 / 2}>
            <Text use="div" color="grey-tone-50" variant="caption">
              Score
            </Text>
            <Text use="div" color="grey-tone-50" variant="h5">
              {scorecard.score ? (
                <>
                  {formatPercentage(scorecard.score)} ({scorecard.points}/
                  {scorecard.maximum_points})
                </>
              ) : (
                '-'
              )}
            </Text>
          </Box>
        </Flex>
      </Widget>
      <Widget p="s-16" mb="s-16">
        <Flex>
          <Box width={1 / 2}>
            <Text use="div" color="grey-tone-50" variant="caption">
              <HStack gap="s-4" align="center">
                Expected seniority
                <Tooltip
                  placement="top"
                  text="This is based on the candidate’s expectations"
                >
                  <InfoOutline size={16} />
                </Tooltip>
              </HStack>
            </Text>
            <Text use="div" color="grey-tone-50" variant="h5">
              {expectedSeniority?.name || '-'}
            </Text>
          </Box>
          <Box width={1 / 2}>
            <Text use="div" color="grey-tone-50" variant="caption">
              <HStack gap="s-4" align="center">
                Evaluated seniority
                <Tooltip placement="left" text="This is evaluation by the interviewer">
                  <InfoOutline size={16} />
                </Tooltip>
              </HStack>
            </Text>
            <Text display="div" color="grey-tone-50" variant="h5">
              <Flex alignItems="center">
                {scorecard.evaluated_seniority?.name || '-'}
                {scorecard.evaluated_seniority && expectedSeniority && (
                  <Box ml="s-4">
                    {scorecard.evaluated_seniority.level > expectedSeniority.level && (
                      <Icon name="ArrowUp" color={Token.color.green} size={18} />
                    )}
                    {scorecard.evaluated_seniority.level < expectedSeniority.level && (
                      <Icon name="ArrowDown" color={Token.color.red} size={18} />
                    )}
                  </Box>
                )}
              </Flex>
            </Text>
          </Box>
        </Flex>
      </Widget>
    </>
  )
}

export default Overview
